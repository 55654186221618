import React, { useMemo } from 'react'
import { Popover } from '@/components/common'
import { Bell } from '@/components/svg'
import useNotifications from '@/services/hooks/useNotifications'
import { BellNotificationList } from '@/components/notifications/BellNotifications'
import ABTester, { ABTestProps } from '@/components/ABTester'

export interface Props {
  navParent?: 'Investors' | 'Creators'
  showNavigation: boolean
}

export const BellNotificationPopover = () => {
  const { notifications } = useNotifications()

  const hasNotifications = useMemo(
    () => notifications.filter((n) => n.type === 'bell' && !n.seen).length > 0,
    [notifications]
  )

  return (
    <Popover
      content={<BellNotificationList className="w-[280px] p-3 pl-0 ml-10" />}
      placement="bottom"
      className="mr-2"
      label={
        hasNotifications ? 'View unseen notifications' : 'View notifications'
      }
      theme="light"
    >
      <div className="relative">
        <Bell className="w-7 h-7" />
        {hasNotifications && (
          <div className="absolute top-[3px] right-[3px] w-2 h-2 bg-oxide-3 rounded-full" />
        )}
      </div>
    </Popover>
  )
}

const ABBellNotificationPopover = () => {
  return (
    <ABTester name="user_notifications">
      {({ decision }: ABTestProps) => {
        if (decision.variationKey === 'on') {
          return <BellNotificationPopover />
        }

        return null
      }}
    </ABTester>
  )
}

export default ABBellNotificationPopover
