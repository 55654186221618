import useSWR from 'swr'

interface Props {
  key: string | false
  request: () => Promise<any>
  resolve?: (data: any) => any
  fallbackData?: any
  swrOptions?: any
}

export interface ApiRes<T> {
  data: T
  isLoading: boolean
  isError: boolean | Error
}

const useApi = ({ key, request, resolve, fallbackData, swrOptions }: Props) => {
  const fetcher = () =>
    request().then((data: any) => {
      return resolve ? resolve(data) : data
    })

  const { data, error, mutate } = useSWR(key, fetcher, {
    errorRetryCount: 0,
    fallbackData,
    ...swrOptions,
  })

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  }
}

export default useApi
