import React, { useContext, useMemo } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { Popover as UIPopover } from '@headlessui/react'
import { Popover, Button, Text, ButtonBase } from '@/components/common'
import { AngelFundingLogo, Menu, Profile } from '@/components/svg'
import UserMenu from '../UserMenu'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { BellNotificationPopover } from '@/components/notifications/BellNotifications'
import ABTester, { ABTestProps } from '../ABTester'
import useAuth from '@/services/hooks/useAuth'
import Image from '@/components/contentful/Image'
import SiteAssetsContext from '@/components/context/SiteAssetsContext'
import { whiteLabelUtil } from '@/utils'

export interface Props {
  navParent?: 'Investors' | 'Creators'
  showNavigation: boolean
}

export const Header = ({
  navParent = 'Investors',
  showNavigation,
}: Props): JSX.Element => {
  const { user, openModal } = useAuth()
  const router = useRouter()
  const siteAssets = useContext(SiteAssetsContext)

  const navItems = useMemo(
    () => [
      {
        label: 'Investors',
        href: '/',
        isSelected: navParent === 'Investors',
      },
      {
        label: 'Creators',
        href: '/apply',
        isSelected: navParent === 'Creators',
      },
    ],
    [navParent]
  )

  return (
    <header id="header__main">
      <div className="relative w-full flex flex-col bg-gray-9 text-black justify-center items-start font-whitney z-[1000]">
        <div className="flex w-full justify-center items-center h-[56px] md:h-[72px]">
          {showNavigation && (
            <div className="absolute left-4 md:hidden">
              <Popover
                content={
                  <nav className="flex flex-col w-[280px] p-3">
                    {navItems.map(({ label, href }) => (
                      <li
                        key={href}
                        className="text-left list-none mb-2 last:mb-0"
                      >
                        <UIPopover.Button as="div">
                          <ButtonBase onClick={() => Router.push(href)}>
                            <Text preset="body.md" as="span">
                              {label}
                            </Text>
                          </ButtonBase>
                        </UIPopover.Button>
                      </li>
                    ))}
                  </nav>
                }
                placement="bottom"
                label="Main Menu"
                theme="light"
              >
                <Menu className="w-8 h-8" />
              </Popover>
            </div>
          )}

          <Link href={'/'}>
            <a
              aria-label="angel-funding-logo"
              className={classNames({
                'md:absolute md:left-4': true,
              })}
            >
              {siteAssets && (
                <h1 className="text-[0]">
                  {siteAssets.title || 'Angel Funding'}
                </h1>
              )}
              {siteAssets && siteAssets.logo && (
                <Image
                  image={siteAssets.logo}
                  height={32}
                  className="h-[26px] md:h-[32px]"
                />
              )}
              {siteAssets && !siteAssets.logo && (
                <AngelFundingLogo className="h-[26px] md:h-[32px] fill-black" />
              )}
            </a>
          </Link>

          {showNavigation && (
            <nav className="hidden md:flex">
              {navItems.map(({ label, href, isSelected }) => (
                <li key={href} className="list-none">
                  <Link href={href}>
                    <a
                      className={classNames({
                        'mx-1 rounded-lg px-4 py-3 transition-bg ease-in-out duration-300':
                          true,
                        'bg-black text-white': isSelected,
                        'hover:bg-gray-8': !isSelected,
                      })}
                    >
                      <Text preset="body.lg" as="span">
                        {label}
                      </Text>
                    </a>
                  </Link>
                </li>
              ))}
            </nav>
          )}

          {user && (
            <div className="absolute right-4 flex items-center">
              <BellNotificationPopover />

              <Popover
                content={
                  <UserMenu.LoggedIn className="flex flex-col w-[280px] p-3" />
                }
                placement="bottom"
                label="User Menu"
                theme="light"
              >
                <Profile className="w-7 h-7" />
              </Popover>
            </div>
          )}

          {/* Explicitly check that auth is null to avoid SSR/hydration issues */}
          {user === null && (
            <div className="absolute flex items-center right-4">
              <Popover
                content={
                  <UserMenu.LoggedOut className="flex flex-col w-full p-3" />
                }
                placement="bottom"
                label="Login or Sign Up"
                className="md:hidden"
                theme="light"
              >
                <Profile className="w-7 h-7" />
              </Popover>

              <div className="hidden md:block">
                <Button
                  variant="text"
                  size="sm"
                  color="black"
                  onClick={() => {
                    openModal('login')

                    SegmentHandler.track('On Tap', {
                      location: router.asPath,
                      section_name: 'header',
                      element_name: 'menu_item',
                      value: 'Login',
                      action_type: 'tap',
                    })
                  }}
                >
                  Log In
                </Button>

                <Button
                  variant="text"
                  size="sm"
                  color="black"
                  className="ml-1"
                  onClick={() => {
                    openModal('register')

                    SegmentHandler.track('On Tap', {
                      location: router.asPath,
                      section_name: 'header',
                      element_name: 'menu_item',
                      value: 'Sign Up',
                      action_type: 'tap',
                    })
                  }}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

const ABHeader = (props: Props) => {
  return (
    <ABTester name="header_navigation">
      {({ decision }: ABTestProps) => (
        <Header {...props} showNavigation={decision.variationKey === 'on'} />
      )}
    </ABTester>
  )
}

const WhiteLabelHeader = (props: Props) => {
  if (whiteLabelUtil.isWhiteLabel()) {
    return <Header {...props} showNavigation={false} />
  }

  return <ABHeader {...props} />
}

export default WhiteLabelHeader
